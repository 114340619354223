footer {
    background: #000;
    padding: 18px 0;
    width: 100%;
    position: absolute;
    bottom: 0;
}
.drow{
    display: flex;
    align-items: center;
    --bs-gutter-x: 0;
}
.pl{
    padding-left: 20px;
}
.pr{
    padding-right: 20px;
}
.foot1{
    order: 1;
}

.foot2{
    order: 2;
    color: #fff;
    font-size: 14px;
    text-align: center;
}

.foot3{
    order: 3;
    text-align: right;
}

.foot1 a{
    color: #fff;
    text-decoration: none;
    font-size: 36px;
}

.foot3 a{
    color: #fff;
    text-decoration: none;
    font-size: 14;
}

@media (max-width: 576px){
    .foot1{
        order: 1;
        text-align: center;
    }
    
    .foot2{
        margin-top: 15px;
        order: 3;
        color: #fff;
        font-size: 14px;
        text-align: center;
    }
    
    .foot3{
        margin-top: 15px;
        order: 2;
        text-align: center;
    }
    
    .foot1 a{
        color: #fff;
        text-decoration: none;
        font-size: 36px;
    }
    
    .foot3 a{
        color: #fff;
        text-decoration: none;
        font-size: 14;
    }
}
@media (min-width: 576px){

    .foot2{
        order: 2;
        color: #fff;
        font-size: 14px;
        text-align: right;
        padding-right: 20px;
    }
}

@media (min-width: 1200px){
    .foot2 {
        text-align: center;
    }
}