p{
  margin: 0;
}
.paddr{
  padding-right: 0;
}

.row {
  margin-top: 0 !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
}


.mt{
  margin-bottom: 200px !important;
  clear: both;
}
.image-section {
  padding: 0px;
  width: 100%;
  background-size: cover;
  background-position: top right;
  height: 350px;
  margin-top: 15px;
  background-size: cover;
}
.texts{
  padding-left: 20px;
}

.title-text{
  font-size: 20px;
  font-weight: 500;
}
.subtitle{
  font-size: 14px;
  font-weight: 500;
  color: dimgray;
}
.fs{
  font-size: 14px;
}
.image-section img {
  width: 100%;
  height: 350px;
  position: relative;
}

.list-group-item.active{
  background-color: dimgray;
  color: #fff;
  border: 1px solid dimgray;
}

.profile{
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile p {
  margin: 0;
  padding: 5px;
}
.profile img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.tab-content{
  background: #fff;
  padding: 15px;
  border-radius: 15px;
}
.pro{
  background: #fff;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;
  margin-top: 25px;
  margin-bottom: 25px;

}
.social a {
  text-decoration: none;
  color: #000;
  /* white-space: nowrap; */
}


.rate {
  height: 30px;
  padding: 0 10px;
  margin: auto;
  text-align: center;
}
.rate:not(:checked) > input {
  position:absolute;
  display: none;
}
.rate:not(:checked) > label {
  float:right;
  width:1em;
  overflow:hidden;
  white-space:nowrap;
  cursor:pointer;
  font-size:30px;
  color:#ccc;
}
.rate:not(:checked) > label:before {
  content: '★ ';
}
.rate > input:checked ~ label {
  color: #ffc700;    
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
  color: #deb217;  
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: #c59b08;
}

.pr-3{
  padding-right: 15px;
}

.detail-modal .modal-content {
  min-width: 500px !important;
  width: auto !important;
}

.gallery-item {
  position: relative;
  color: #fff;
  cursor: pointer;
  clear: both;
}

.gallery-item:hover .gallery-item-info,
.gallery-item:focus .gallery-item-info {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.gallery-item-info {
  display: none;
}

.gallery-item-info li {
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
}

.gallery-item-likes {
  margin-right: 2.2rem;
  text-align: center;
}

.gallery-item-type {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2.5rem;
  text-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.1);
}

.fa-clone,
.fa-comment {
  transform: rotateY(180deg);
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 1024px){
  .pro{
    display: block;
  }
  .pro .social {
    text-align: center;
    padding-top: 20px;
  }

}

.our-team{
  padding: 20px 15px 30px;
  background: #fff;
  border-radius: 15px;
  text-align: center;
}
.our-team .pic{
  display: inline-block;
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 10px;
  margin-bottom: 25px;
  transition: all 0.5s ease 0s;
}
.our-team:hover .pic{
  background: dimgray;
  border-radius: 50%;
}
.pic img{
  width: 100%;
  height: auto;
  border-radius: 50%;
}
.our-team .title{
  display: block;
  font-size: 20px;
  font-weight: 600;
  color: #2e282a;
  text-transform: uppercase;
  margin: 0 0 7px 0;
}
.our-team .post{
  display: block;
  font-size: 15px;
  color: dimgray;
  text-transform: capitalize;
  margin-bottom: 15px;
}
.our-team .social{
  padding: 0;
  margin: 0;
  list-style: none;
}
.our-team .social li{
  display: inline-block;
  margin-right: 5px;
}
.our-team .social li a{
  display: block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  font-size: 15px;
  color: dimgray;
  border: 1px solid dimgray;
  transition: all 0.5s ease 0s;
}
.our-team:hover .social li a{
  background: dimgray;
  color: #fff;
}
@media only screen and (max-width: 990px){
  .our-team{ margin-bottom: 30px; }
}

@media only screen and (max-width: 768px) {
  .detail-modal .modal-content {
    min-width: 100% !important;
    width: auto !important;
  }

  .image-section {
    height: 200px;
    background-size: contain;
    background-position: center;
  }

  .rate {
    padding: 0 0px;
  }

  .rate:not(:checked) > label {
    font-size: 25px;
  }
}

@media only screen and (max-width: 376px) {
  .mt {
    margin-bottom: 220px !important;
  }
}
