header{
  padding-bottom: 65px;
}
.navbar {
  --bs-navbar-padding-y: 0;
}
.search-box{
  width: fit-content;
  height: fit-content;
  position: relative;
}
.input-search{
  height: 30px;
  width: 30px;
  border-style: none;
  font-size: 18px;
  letter-spacing: 2px;
  outline: none;
  border-radius: 25px;
  transition: all .5s ease-in-out;
  background-color: transparent;
  padding-right: 30px;
  color: #000;
  box-shadow: 0 0 0 1px #000;
}
.login{
  margin-left: 10px;
  font-size: 20px;
  cursor: pointer;
}
.input-search::placeholder{
  background: #fff !important;
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 100;
}
.btn-search{
  width: 30px;
  height: 30px;
  border-style: none;
  font-size: 18px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color:#000 ;
  background-color:transparent;
  pointer-events: painted;  
}
.btn-search:focus ~ .input-search{
  width: 200px;
  border-radius: 5px;
  background-color: transparent;
  border-bottom:1px solid rgba(255,255,255,.5);
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}
.input-search:focus{
  width: 200px;
  border-radius: 5px;
  background-color: transparent;
  border-bottom:1px solid rgba(255,255,255,.5);
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}
.navbar input[type="checkbox"],
.navbar .hamburger-lines{
    display: none;
}
 
.cont1{
  max-width: 1200px;
  width: 90%;
  margin: auto;
}

.navbar{
    box-shadow: 0px 5px 10px 0px #aaa;
    position: fixed;
    width: 100%;
    background: #fff;
    color: #000;
    opacity: 0.85;
    z-index: 100;
}

.navbar-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    align-items: center;
}
.menu-items{
    order: 2;
    display: flex;
    margin: 0;
}
.logo{
    order: 1;
    font-size: 2.3rem;
}
.cont{
  order: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search2{
  display: none !important;
}
.menu-items li{
    list-style: none;
    margin-left: 1.5rem;
    font-size: 14px;
}

.navbar a{
    color: #000;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease-in-out;
}

.navbar a:hover{
    color: #117964;
}

.navbar a.active {
  color: #117964;
}

.App {
  background-color: white;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
.modal-content {
  width: auto !important;
}
.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: auto !important;
  --bs-modal-width: auto !important;
}
.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

@media (max-width: 1024px){
    .navbar{
        opacity: 0.95;
    }
    .search-box{
      display: none;
    }
    .btn-search:focus ~ .input-search{
      width: 150px;
    }
    .input-search:focus{
      width: 150px;
    }
    .navbar-container input[type="checkbox"],
    .navbar-container .hamburger-lines{
        display: block;
    }

    .search2{
      display: block !important;
      padding: 10px;
      margin-left: -15px;
    }

    .navbar-container{
        display: block;
        position: relative;
        height: 64px;
    }

    .navbar-container input[type="checkbox"]{
        position: absolute;
        display: block;
        height: 22px;
        width: 20px;
        top: 20px;
        left: 20px;
        z-index: 5;
        opacity: 0;
        cursor: pointer;
    }

    .navbar-container .hamburger-lines{
        display: block;
        height: 20px;
        width: 27px;
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .navbar-container .hamburger-lines .line{
        display: block;
        height: 4px;
        width: 100%;
        border-radius: 10px;
        background: #333;
    }
    
    .navbar-container .hamburger-lines .line1{
        transform-origin: 0% 0%;
        transition: transform 0.3s ease-in-out;
    }

    .navbar-container .hamburger-lines .line2{
        transition: transform 0.2s ease-in-out;
    }

    .navbar-container .hamburger-lines .line3{
        transform-origin: 0% 100%;
        transition: transform 0.3s ease-in-out;
    }

    .navbar .menu-items{
        padding-top: 100px;
        background: #fff;
        height: 100vh;
        max-width: 300px;
        transform: translate(-150%);
        display: flex;
        flex-direction: column;
        margin-left: -50px;
        padding-left: 50px;
        transition: transform 0.5s ease-in-out;
        box-shadow:  5px 0px 10px 0px #aaa;
        overflow: auto;
    }

    .navbar .menu-items li{
        margin-bottom: 1.8rem;
        font-size: 1.1rem;
        font-weight: 500;
    }

    .logo{
        position: absolute;
        top: 0px;
        right: calc(50% - 45px);
        font-size: 2.5rem;
    }
    .cont{
      position: absolute;
      top: 10px;
      right: 15px;
    }

    .navbar-container input[type="checkbox"]:checked ~ .menu-items{
        transform: translateX(0);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line1{
        transform: rotate(45deg);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line2{
        transform: scaleY(0);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line3{
        transform: rotate(-45deg);
    }

}

@media (max-width: 500px){
    .navbar-container input[type="checkbox"]:checked ~ .logo{
        display: none;
    }
    .Auth-form {
      width: 320px;
    }
}

@media (min-width: 1200px){

  .menu-items li{ 
    font-size: 18px;
  }

}