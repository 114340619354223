.address{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 20px;
    margin-top: 40px;
    border-radius: 10px;
}
.address p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
}

.sanal input {
    display: block;
    width: 100%;
    /* margin: 1em; */
    padding: 1em;
    /* width: 35em; */
    border-radius: 8px;
    border-style: none;
    border: 1px solid #e4e6e8;
    transition: 0.1s ease;
  }

  .sanal textarea {
    display: block;
    width: 100%;
    /* margin: 1em; */
    padding: 1em;
    /* width: 35em; */
    border-radius: 8px;
    border-style: none;
    border: 1px solid #e4e6e8;
    transition: 0.1s ease;
  }
  
  .sanal input:hover {
    border-color: palevioletred;
  }

  .sanal button {
    margin: 0.8em;
    padding: 1em;
    border: 1px solid #e4e6e8;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.1s ease-in;
  }
  
  .sanal button:hover {
    background-color: palevioletred;
    color: white;
  }
  .address img{
    width: 100%;
    height: auto;
  }