.main-header-txt {
    text-align: center;
    font-size: 18px;
    margin-top: 15px;
}

.special-section {
    padding-bottom: 80px;
}
.company-logo img {
    width: auto !important;
    height: 50px;
    clear: both;
}

.corps {
    width: 100% !important;
    clear: both;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    clear: both;
}

.image-card{
    padding: 0 !important;
}
.image-card img{
    height: 169px;
    width: auto;
    object-fit: cover;
    object-position:  top;
}


.body-card{
    min-height: 100px;
}


.carousel-item-padding {
    padding-right: 10px;
    padding-left: 10px;
}
.section{
    padding-bottom: 90px;
}
.sec{
    background: #fff;
    padding-top: 10px;
    margin-top: 20px;
}
.main{
    background: #fff;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.name {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}

.work-job {
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    opacity: 0.6;
}

.error-page {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 100px;
    font-size: 55px;
}

.banner-link {
    color: white;
    text-decoration: none;
}

@media (max-width: 576px){
    .section {
        padding-bottom: 181px;
    }
}
@media (min-width: 576px){
    .section {
        padding-bottom: 114px;
    }
}