.card {
    border-radius: 5px;
    box-shadow: 7px 7px 13px 0px rgba(50, 50, 50, 0.22);
    padding: 15px;
    margin: 10px;
    transition: all 0.3s ease-out;
  }
  
  .card:hover {
    transform: translateY(-5px);
    cursor: pointer;
  }
  
  .card p {
    color: #a3a5ae;
    font-size: 16px;
  }
  
  .image {
    float: right;
    max-width: 64px;
    max-height: 64px;
  }

  .fb-padd {
    padding-top: 10px !important;
  }

  .expire-date-pro {
    font-size: 13px;
  }