.scrolled-aria{
  max-height: calc(100vh - 218px - 2rem);
  overflow: auto;
  margin-top: 10px;
}

.map-aria {
  height: calc(100vh - 218px - 2rem);
}

@media (max-width: 576px){
  .map-aria {
    /* height: calc(100vh - 310px - 2rem); */
    height: 500px;
    margin-bottom: 210px;
  }
}
@media (min-width: 576px){
  .map-aria {
    height: calc(100vh - 242px - 2rem);
  }
}

.one-item .accordion-button::after {
  transform: rotate(270deg);
}

.accordion-button:not(.collapsed)::after {
  transform: rotate(270deg);
}

.salbar {
  cursor: pointer;
}

.salbar:hover {
  color: #0c63e4;
}

.pt-3 {
  margin: auto;
}
.dflex {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  padding: 10px;
  position: relative;
}
.dflex :hover {
  color: #0c63e4;
}
.dflex ::after {
  color: #0c63e4;
  background-color: #e7f1ff;
}

.arrow-right {
  width: 0; 
  height: 0; 
  border-top: 60px solid transparent;
  border-bottom: 60px solid transparent;
  border-left: 60px solid green;
}
.imageMap img{
  width: 100%;
  height: 100px;
}
.imageMap p{
  font-size: 14px;
  font-weight: 700;
  padding-top: 10px;
}
.imageMap a {
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;
  padding-top: 10px;
}


.arrow1 {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right1 {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}